<template>
  <sticky-cta-page :page-background-color="ctaProps.backgroundColor" :cta-props="ctaProps" @click="toHome">
  <div class="join-challenge-page">
    <div class="image">
    <div v-if="loading">
      <lottie-player src="/animations/new-brand-loader.json" autoplay loop class="margin-bottom-m" preserveAspectRatio="xMidYMax slice"/>
    </div>
      <div v-else-if="hasError">
        <img :src="require('@/assets/generic-faliure.png')" alt="error"/>
      </div>
      <div v-else>
        <img :src="require('@/assets/bank-connection-success.png')" alt="successfully joined challenge" />
      </div>
    </div>
    <div class="texts">
      <span class="ri-large-body" v-if="loading">מצטרף לאתגר...</span>
      <span class="ri-large-body" v-else-if="hasError">לא הצלחנו לצרף אותך לאתגר, ניתן לפנות לתמיכה</span>
      <template v-else>
        <span class="ri-large-headline ri-font-weight-bold">
          הצטרפת בהצלחה לאתגר
        </span>
        <span class="ri-large-body">הודעת הווטסאפ הראשונה כבר אצלך,</span>
        <span class="ri-large-body">איזה כיף!</span>
      </template>
    </div>
  </div>
  </sticky-cta-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'JoinChallengePage',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    challengeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ctaProps: {
        buttonProps: {
          loading: this.loading,
          title: 'בחזרה לתזרים',
        },
        backgroundColor: BaseUI.Colors.riseupBeige,
      },
    };
  },
  async created() {
    await this.joinChallenge({ challengeId: this.challengeId });
  },
  computed: {
    ...mapState('challenges', ['loading', 'hasError']),
  },
  methods: {
    ...mapActions('challenges', ['joinChallenge']),
    toHome() {
      this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.join-challenge-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $riseup-beige;
  padding: $ri-spacing-xxl $ri-spacing-xl;

  .image {
    margin-bottom: $ri-spacing-xxl;
    img {
      width: 100%;
    }
  }
  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .ri-large-headline {
      margin-bottom: 10px;
    }
  }
}
</style>
